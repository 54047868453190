<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <!-- Use the fileUrl if available, otherwise use the default avatar -->
          <v-img :src="library.fileUrl ? library.fileUrl : require('@/assets/images/avatars/3.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="text-center">
  <span class="text--primary font-weight-semibold mb-n1 custom-padding">
    {{ userEmail }}
  </span>
</div>


      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<script>
import axios from "axios";
import { mapState } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/auth";
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from "@mdi/js";

export default {
  data() {
    return {
      library: {}, // Add library object to store the fetched data
    };
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    };
  },
  created() {
    const storedOwnerId = localStorage.getItem('ownerId');
    if (storedOwnerId) {
      this.ownerId = parseInt(storedOwnerId, 10);
      this.fetchLibraryDetails();
    } else {
      console.error('Owner ID not found in local storage');
    }
  },
  methods: {
    async fetchLibraryDetails() {
      try {
        const response = await axios.get(`https://qorani-qorani-test.gbapgy.easypanel.host/library/library/getByOwnerId/${this.ownerId}`);
        this.library = response.data;
      } catch (error) {
        console.error('Failed to fetch library details:', error);
      }
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          console.error("Error during logout:", error);
        });
    },
  },
  computed: {
    ...mapState(["userEmail"]),
  },
};

</script>

<style lang="scss">
.custom-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>

<template>
   <section class="publish-form-section">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Listes des livres 
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="bookHeaders"
              :items="books"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon @click="openConfirmDialog(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>

            <!-- Text field for book search -->
            <v-col>
    <v-menu
      v-model="menuVisible"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="keyword"
          label="Rechercher des livres"
          outlined
          @input="searchBooks"
          :rules="[v => !!v || 'Title is required']"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <!-- Dropdown for Search Results -->
      <v-list v-if="searchResults.length">
        <v-list-item-group>
          <v-list-item
            v-for="book in searchResults"
            :key="book.id"
            @click="selectBook(book)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ book.titre }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-col>

            <v-btn
              color="info"
              @click="addBookToPublishingHouse"
              :disabled="!selectedBook"
            >
            Ajouter un livre
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="$router.go(-1)">Retour</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Confirmation Dialog -->
    <v-row justify="center">
      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Êtes-vous sûr de vouloir supprimer ce livre ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="closeConfirmDialog"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text  @click="confirmDelete">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    
    <div>
      <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
      <v-alert v-if="successMessage" type="success">{{ successMessage }}</v-alert>
    </div>
  </v-container>
</section>
</template>

<script>
import axios from 'axios';
import Constant from "@/utils/constants";

export default {
  props: ['publishingHouseId'],
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      bookHeaders: [
        { text: 'Title', value: 'titre' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      books: [],
      allBooks: [],
      selectedBook: null,
      publishingHouse: {
        id: this.publishingHouseId,
        name: '',
      },
      keyword: '',
      searchResults: [], // Array to hold search results
      menuVisible: false,
      confirmDialog: false, // Controls visibility of the confirmation dialog
      bookToDelete: null, // Stores the book to be deleted
    };
  },
  created() {
    this.fetchBooks();
    this.fetchAllBooks();
  },
  methods: {
    fetchBooks() {
      axios.post(Constant.QORANI + `library/book/getallByPublishHouseId`, {
        fieldId: this.publishingHouseId,
        pageNumber: 0,
        pageSize: 10,
      })
        .then(response => {
          this.books = response.data;
        })
        .catch(error => {
          console.error('Error fetching books:', error);
        });
    },
    fetchAllBooks() {
      axios.get(Constant.QORANI + `library/book/getall`)
        .then(response => {
          this.allBooks = response.data;
        })
        .catch(error => {
          console.error('Error fetching all books:', error);
        });
    },
    searchBooks() {
      if (this.keyword.trim() === '') {
        this.searchResults = [];
        return;
      }
      // Example: Perform a search based on keyword (assuming allBooks is the source)
      this.searchResults = this.allBooks.filter(book =>
        book.titre.toLowerCase().includes(this.keyword.toLowerCase())
      );
      this.menuVisible = true; // Show the dropdown when searching
    },
    selectBook(book) {
      this.selectedBook = book;
      this.keyword = book.titre || '';
      this.searchResults = [];
      this.menuVisible = false; // Close the dropdown when a book is selected
    },
    addBookToPublishingHouse() {
      axios.get(Constant.QORANI+`library/publishHouse/addBook/${this.selectedBook.id}/toPublishHouse/${this.publishingHouse.id}`)
        .then(response => {
          if (response.data.success === true) {
            this.fetchBooks(); // Refresh the list of books
            this.selectedBook = null; // Reset selected book
            this.successMessage = 'Livre ajouté avec succès.';
            this.errorMessage = ''; // Clear previous error messages
            this
          } else {
            this.errorMessage = 'Échec de l\'ajout du livre. Veuillez réessayer.';
            this.successMessage = ''; // Clear previous success messages
          }
        })
        .catch(error => {
          console.error('Error adding book:', error.response);
          this.errorMessage = 'Échec de l\'ajout du livre. Veuillez réessayer.';
          this.successMessage = ''; // Clear previous success messages
        });
    },
    openConfirmDialog(book) {
      this.bookToDelete = book;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.bookToDelete = null;
    },
    confirmDelete() {
      this.removeBookFromPublishingHouse(this.bookToDelete);
      this.closeConfirmDialog();
    },
    removeBookFromPublishingHouse(book) {
      axios.get(Constant.QORANI+`library/publishHouse/removeBook/${book.id}/fromPublishHouse/${this.publishingHouseId}`)
        .then(() => {
          this.fetchBooks();
          this.successMessage = 'Livre supprimé avec succès.';
          this.errorMessage = ''; // Clear any previous error messages
        })
        .catch(error => {
          console.error('Erreur lors de la suppression du livre :', error.response);
          this.errorMessage = 'Échec de la suppression du livre.';
          this.successMessage = ''; // Clear any previous success messages
        });
    },
    handleClickOutside(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.menuVisible = false; // Close the dropdown when clicking outside
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.v-text-field {
  margin-top: 15px;
}
.publish-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>

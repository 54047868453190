import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userEmail: "",
  },
  mutations: {
    setUserEmail(state, email) {
      state.userEmail = email;
    },
  },
});

export default store;

require("./overrides.scss");

export default {
  theme: {
    themes: {
      light: {
        primary: "#89d624",
        accent: "#0d6efd",
        secondary: "#8A8D93",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
      },
      dark: {
        primary: "#9155FD",
        accent: "#0d6efd",
        secondary: "#8A8D93",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
      },
    },
  },
};

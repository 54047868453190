<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    v-if="!$route.meta.hideSidebar"
    app
    floating
    width="auto"
    class="app-navigation-menu"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <!--
		<div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
			<router-link to="/" class="d-flex align-center text-decoration-none">
				<v-img
					:src="require('@/assets/images/logos/logo.svg')"
					max-height="200px"
					max-width="200px"
					alt="logo"
					contain
					eager
					class="app-logo me-3"
				></v-img>
				<v-slide-x-transition> </v-slide-x-transition>
			</router-link>
		</div>
  -->

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <!--  <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiFileOutline"></nav-menu-link>-->
      <!--<nav-menu-group title="Produits" :icon="icons.mdiArchive">-->
      <!--nav-menu-link title="ACTION" :to="{ name: 'actions' }"></nav-menu-link>
        <nav-menu-link title="Claims" :to="{ name: 'Claims' }"></nav-menu-link>
        <nav-menu-link title="Groups" :to="{ name: 'Groups' }"></nav-menu-link>
        <nav-menu-link title="Logs" :to="{ name: 'Logs' }"></nav-menu-link>
        <nav-menu-link title="Commentmeta" :to="{ name: 'Commentmeta' }"></nav-menu-link>
        <nav-menu-link title="Comments" :to="{ name: 'Comments' }"></nav-menu-link>
        <nav-menu-link title="Events" :to="{ name: 'Events' }"></nav-menu-link>
        <nav-menu-link title="Slider" :to="{ name: 'Slider' }"></nav-menu-link>
        <nav-menu-link title="Links" :to="{ name: 'Links' }"></nav-menu-link>
        <nav-menu-link title="Option" :to="{ name: 'option' }"></nav-menu-link>
        <nav-menu-link title="Postmeta" :to="{ name: 'postmeta' }"></nav-menu-link>
        <nav-menu-link title="Posts" :to="{ name: 'Posts' }"></nav-menu-link>
        <nav-menu-link title="css" :to="{ name: 'Css' }"></nav-menu-link>
        <nav-menu-link title="Css_bkp" :to="{ name: 'Css_bkp' }"></nav-menu-link>
        <nav-menu-link title="layer animations" :to="{ name: 'layer_animations' }"></nav-menu-link>
        <nav-menu-link title="layer animations bkp" :to="{ name: 'layer_animations_bkp' }"></nav-menu-link>
        <nav-menu-link title="navigations" :to="{ name: 'navigations' }"></nav-menu-link>
        <nav-menu-link title="navigations_bkp" :to="{ name: 'navigations_bkp' }"></nav-menu-link>
        <nav-menu-link title="sliders" :to="{ name: 'sliders' }"></nav-menu-link>
        <nav-menu-link title="sliders_bkp" :to="{ name: 'sliders_bkp' }"></nav-menu-link>
        <nav-menu-link title="slides" :to="{ name: 'slides' }"></nav-menu-link>
        <nav-menu-link title="slides bkp" :to="{ name: 'slides_bkp' }"></nav-menu-link>
        <nav-menu-link title="static_slides" :to="{ name: 'static_slides' }"></nav-menu-link>
        <nav-menu-link title="static slides bkp" :to="{ name: 'static_slides_bkp' }"></nav-menu-link>
        <nav-menu-link title="images" :to="{ name: 'images' }"></nav-menu-link>
        <nav-menu-link title="termmeta" :to="{ name: 'termmeta' }"></nav-menu-link>
        <nav-menu-link title="terms" :to="{ name: 'terms' }"></nav-menu-link>
        <nav-menu-link title="term relationships" :to="{ name: 'term_relationships' }"></nav-menu-link>
        <nav-menu-link title="term_taxonomy" :to="{ name: 'term_taxonomy' }"></nav-menu-link>
        <nav-menu-link title="usermeta" :to="{ name: 'usermeta' }"></nav-menu-link>
        <nav-menu-link title="users" :to="{ name: 'users' }"></nav-menu-link>
        <nav-menu-link title="Admin notes" :to="{ name: 'wc_admin_notes' }"></nav-menu-link>
        <nav-menu-link title="Admin note actions" :to="{ name: 'wc_admin_note_actions' }"></nav-menu-link>
        <nav-menu-link title="Category lookup" :to="{ name: 'wc_category_lookup' }"></nav-menu-link>
        <nav-menu-link title="Customer lookup" :to="{ name: 'wc_customer_lookup' }"></nav-menu-link>
        <nav-menu-link title="download_log" :to="{ name: 'wc_download_log' }"></nav-menu-link>
        <nav-menu-link title="Order Coupon_lookup" :to="{ name: 'wc_order_coupon_lookup' }"></nav-menu-link>
        <nav-menu-link title="Order product lookup" :to="{ name: 'wc_order_product_lookup' }"></nav-menu-link>
        <nav-menu-link title="Order Stats" :to="{ name: 'wc_order_stats' }"></nav-menu-link>
        <nav-menu-link title="Order Tax_lookup" :to="{ name: 'wc_order_tax_lookup' }"></nav-menu-link>
        <nav-menu-link title="product download" :to="{ name: 'wc_product_download_directories' }"></nav-menu-link>
        <nav-menu-link title="Product Meta " :to="{ name: 'wc_product_meta_lookup' }"></nav-menu-link>
        <nav-menu-link title="Product Attributes " :to="{ name: 'wc_product_attributes_lookup' }"></nav-menu-link>
        <nav-menu-link title="rate limits " :to="{ name: 'wc_rate_limits' }"></nav-menu-link>
        <nav-menu-link title="reserved stock" :to="{ name: 'wc_reserved_stock' }"></nav-menu-link>
        <nav-menu-link title="Rate Classes" :to="{ name: 'wc_tax_rate_classes' }"></nav-menu-link>
        <nav-menu-link title="Webhooks" :to="{ name: 'wc_webhooks' }"></nav-menu-link>
        <nav-menu-link title="Api Keys" :to="{ name: 'woocommerce_api_keys' }"></nav-menu-link>
        <nav-menu-link title=" attribute_taxonomies" :to="{ name: 'woocommerce_attribute_taxonomies' }"></nav-menu-link>
        <nav-menu-link
          title=" downloadable product "
          :to="{ name: 'downloadable_product_permissions' }"
        ></nav-menu-link>
        <nav-menu-link title="Log" :to="{ name: 'woocommerce_log' }"></nav-menu-link>
        <nav-menu-link title="Order itemmeta" :to="{ name: 'woocommerce_order_itemmeta' }"></nav-menu-link>
        <nav-menu-link title=" Order items" :to="{ name: 'woocommerce_order_items' }"></nav-menu-link>
        <nav-menu-link title=" Payment tokenmeta" :to="{ name: 'woocommerce_payment_tokenmeta' }"></nav-menu-link>
        <nav-menu-link title="Payment Tokens " :to="{ name: 'woocommerce_payment_tokens' }"></nav-menu-link>
        <nav-menu-link title=" Sessions " :to="{ name: 'woocommerce_sessions' }"></nav-menu-link>
        <nav-menu-link title="Shipping Zones " :to="{ name: 'woocommerce_shipping_zones' }"></nav-menu-link>
        <nav-menu-link
          title="Shipping locations "
          :to="{ name: 'woocommerce_shipping_zone_locations' }"
        ></nav-menu-link>
        <nav-menu-link title="Shipping Methods" :to="{ name: 'woocommerce_shipping_zone_methods' }"></nav-menu-link>
        <nav-menu-link title="Tax Rates " :to="{ name: 'woocommerce_tax_rates' }"></nav-menu-link>
        <nav-menu-link title="Tax Rates locations " :to="{ name: 'woocommerce_tax_rate_locations' }"></nav-menu-link>
        <nav-menu-link title="Query Cache" :to="{ name: 'woof_query_cache' }"></nav-menu-link>
        <nav-menu-link title="Wapo Addons" :to="{ name: 'yith_wapo_addons' }"></nav-menu-link>
        <nav-menu-link title="Wapo Blocks" :to="{ name: 'yith_wapo_blocks' }"></nav-menu-link-->
      <!-- <nav-menu-link title="Tous les produits" :to="{ name: 'Tous les produits' }"></nav-menu-link>-->
      <!--	<nav-menu-link
					title="test"
					:to="{ name: 'icons' }"
					:icon="icons.mdiShapePlus"
				></nav-menu-link>-->
        
      <nav-menu-link
      v-if="isAdmin"
        title="Nivaux"
        :to="{ name: 'Level' }"
        :icon="icons.mdiTag"
      ></nav-menu-link>
    
      <nav-menu-link
      v-if="isAdmin"
        title="Livres "
        :to="{ name: 'SousVersetList' }"
        :icon="icons.mdiBookOpenPageVariant"
      ></nav-menu-link>
      <nav-menu-link
      v-if="isAdmin"
        title="Librairies"
        :to="{ name: 'Library' }"
        :icon="icons.mdiWhiteBalanceSunny"
      ></nav-menu-link>
      <nav-menu-link
      v-if="isLib"
        title="Ma librairie"
        :to="{ name: 'my library' }"
        :icon="icons.mdiWhiteBalanceSunny"
      ></nav-menu-link>
      <nav-menu-link
      v-if="isLib"
        title="Mes Livres"
        :to="{ name: 'mon livre' }"
        :icon="icons.mdiBookOpenPageVariant"
      ></nav-menu-link>
      <nav-menu-link
      v-if="isLib"
        title="Maison d'édition"
        :to="{ name: 'publish house' }"
        :icon="icons.mdiAccountEdit"
      ></nav-menu-link>
      
      <nav-menu-link
       v-if="isLib"
        title="Liste des Commandes"
        :to="{ name: 'UserOrderList' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
        
      
      <!--<nav-menu-link
      v-if="isLib"
        title="Données de payement"
        :to="{ name: 'UserPayementData' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>-->
      
      <nav-menu-link
      
        title="Auteurs"
        :to="{ name: 'Auteur' }"
        :icon="icons.mdiAccountGroup"
      ></nav-menu-link>
     
      <nav-menu-link
      v-if="isAdmin"
        title="Categories"
        :to="{ name: 'Category' }"
        :icon="icons.mdiShapePlus"
      ></nav-menu-link>
      
      <nav-menu-link
      v-if="isAdmin"
        title="Maison d'édition"
        :to="{ name: 'SousTheme' }"
        :icon="icons.mdiAccountEdit"
      ></nav-menu-link>
      
     
     
      <nav-menu-link
      v-if="isAdmin"
        title="Utilisateur"
        :to="{ name: 'Recitateur' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>

      <nav-menu-link
      v-if="isAdmin"
        title="Parametre Generaux"
        :to="{ name: 'Parametre' }"
        :icon="icons.mdiFileOutline"
      ></nav-menu-link>
  
      <!--
      <nav-menu-link
        title="Actualité"
        :to="{ name: 'Actuality' }"
        :icon="icons.mdiWhiteBalanceSunny"
      ></nav-menu-link>

      <nav-menu-link
        title="Réclamation"
        :to="{ name: 'Complaint' }"
        :icon="icons.mdiAlertBox"
      ></nav-menu-link>
      -->
      <!--////////////////////////////////-->
      <!--
      <nav-menu-group title="Abonnement" :icon="icons.mdiFileOutline">
        <nav-menu-link
          title="type d'abonnement"
          :to="{ name: 'Subscription' }"
          :icon="icons.mdiAlphaSBoxOutline"
        ></nav-menu-link>

        <nav-menu-link
          title="Abonnement user"
          :to="{ name: 'subscriptionUsers' }"
          :icon="icons.mdiAlphaSBoxOutline"
        ></nav-menu-link>
      </nav-menu-group>
    -->
      <!--////////////////////////////////-->
      <!--
      <nav-menu-group title="Utilisateurs" :icon="icons.mdiFileOutline">
        <nav-menu-link
          title="Nouvel Administrateur"
          :to="{ name: 'NewUser' }"
          :icon="icons.mdiWhiteBalanceSunny"
        ></nav-menu-link>
        <nav-menu-link
          title="Administrateurs"
          :to="{ name: 'Admins' }"
          :icon="icons.mdiWhiteBalanceSunny"
        ></nav-menu-link>
        <nav-menu-link
          title="Utilisateurs"
          :to="{ name: 'User' }"
          :icon="icons.mdiWhiteBalanceSunny"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        title="FAQ"
        :to="{ name: 'Faq' }"
        :icon="icons.mdiFrequentlyAskedQuestions"
      ></nav-menu-link>
    -->
      <!--////////////////////////////////-->
      <!--</nav-menu-group>-->

      <!--   <nav-menu-link
        title="Produits Posté"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>

      <nav-menu-group title="Pages" :icon="icons.mdiFileOutline">
        <nav-menu-link
          title="produits Posté"
          :to="{ name: 'simple-table' }"
          :icon="icons.mdiAccountCogOutline"
        ></nav-menu-link>
      </nav-menu-group>-->

      <!--
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <!--
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiArchive,
  mdiShapePlus,
  mdiTag,
  mdiWhiteBalanceSunny,
  mdiAlertBox,
  mdiAlphaSBoxOutline,
  mdiFrequentlyAskedQuestions,
  mdiBookOpenPageVariant, 
  mdiAccountGroup,
  mdiAccountEdit
} from "@mdi/js";
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";

export default {
  data() {
    return {
      isLib:localStorage.getItem('role')==='LIBRARY',
      isAdmin:localStorage.getItem('role')==='ADMIN',
      
    }
  },
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    
    
    
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiArchive,
        mdiShapePlus,
        mdiTag,
        mdiWhiteBalanceSunny,
        mdiFrequentlyAskedQuestions,
        mdiAlertBox,
        mdiAlphaSBoxOutline,
        mdiBookOpenPageVariant, 
        mdiAccountGroup,
        mdiAccountEdit
            },
      
    };
    
  },
  created() {

   console.log(localStorage.getItem('role'));
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* Scoped style in your component */

/* Use `::v-deep` to style nested elements */
.v-list ::v-deep .v-list-item {
  border-bottom: 1px solid #ddd; /* Add border between items */
  padding: 30px;
}

.v-list ::v-deep .v-list-item:hover {
  background-color: #e0e0e0; /* Change background color on hover */
}

.v-list {
  background-color: #fafafa; /* Change background color of the list */
  padding-top: 58px; /* Add padding around the list */
  border-radius: 4px; /* Add border radius */
}
.v-list-item__title {
  font-weight: bold; /* Change font weight */
  color: #333; /* Change text color */
}

.v-list-item__subtitle {
  color: #666; /* Change subtitle color */
}
/* Navigation Drawer Styles */
.app-navigation-menu {
  background-color: #ffffff; /* Use a white background for a clean look */
  color: #34495e; /* Dark text color for contrast */
  width: 280px; /* Fixed width for the drawer */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Header Styles */
.vertical-nav-header {
  padding: 16px 20px; /* Padding for the header */
  text-align: center; /* Center the logo */
  border-bottom: 1px solid #e0e0e0; /* Light border below header */
}

/* Logo styling */
.app-logo {
  max-height: 60px; /* Limit logo height */
}

/* Navigation Items Styles */
.vertical-nav-menu-items {
  padding: 0; /* Remove default padding */
  margin-top: 20px; /* Spacing from the top */
}

/* Navigation Link Styles */
nav-menu-link {
  display: flex;
  align-items: center; /* Center items vertically */
  padding: 12px 20px; /* Padding for links */
  color: #34495e; /* Dark text color */
  border-radius: 4px; /* Rounded corners for links */
  transition: background-color 0.3s, color 0.3s; /* Transition for hover effects */
}

nav-menu-link:hover {
  background-color: #f1f1f1; /* Light gray background on hover */
  color: #2980b9; /* Change text color on hover */
}

/* Icon Styles */
.icon-edit,
.icon-delete,
.icon-view {
  margin-right: 12px; /* Spacing between icon and text */
  color: #34495e; /* Set icon color */
  transition: color 0.3s; /* Transition for icon color */
}

.icon-edit:hover,
.icon-delete:hover,
.icon-view:hover {
  color: #3498db; /* Change color on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .app-navigation-menu {
    width: 100%; /* Full width on smaller screens */
  }
}

@media (min-width: 769px) {
  .app-navigation-menu {
    width: 280px; /* Default width for larger screens */
  }
}

/* Divider Styling */
.v-divider {
  background-color: #e0e0e0; /* Light color for dividers */
  height: 1px; /* Set height for dividers */
}

/* Active Link Styles */
nav-menu-link.active {
  background-color: #2980b9; /* Highlight active link */
  color: #ffffff; /* White text for active link */
}

/* Tooltip Styling */
.v-tooltip {
  background-color: #2980b9; /* Tooltip background */
  color: #ffffff; /* Tooltip text color */
}

</style>
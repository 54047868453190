import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import VueCarousel from "vue-carousel";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "./firebase/firebaseConfig";
import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import "@mdi/font/css/materialdesignicons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

const userEmail = localStorage.getItem("userEmail");
if (userEmail) {
  store.commit("setUserEmail", userEmail);
}

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

Vue.use(VueLazyload);
Vue.use(VueUploadMultipleImage);
Vue.use(VueCarousel);
Vue.use(Toast, { position: 'top-right' });


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

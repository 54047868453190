<template>
  <section class="libraryBook-form-section">
<v-container>
  <!-- Library Name -->
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="headline">{{ library.name }}</v-card-title>
      </v-card>
    </v-col>
  </v-row>

  <!-- Search and Add/Edit Form -->
  <v-form v-model="valid" @submit.prevent="submitForm" ref="form" class="libraryBook-form">
    <!-- Search Text Field -->
    <v-row>
      <v-col>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="keyword"
        label="Titre"
        outlined
        @input="searchBooks"
        :rules="[v => !!v || 'Titre est requis']"
        :readonly="isEdit"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <!-- Dropdown for Search Results -->
    <v-list v-if="searchResults.length">
      <v-list-item-group>
        <v-list-item
          v-for="book in searchResults"
          :key="book.bookId"
          @click="selectBook(book)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ book.titre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</v-col>
  <v-col><v-text-field v-model="book.nbrPage" label="Nombre de pages" type="number" outlined required  :rules="[v => !!v || 'Nombre de pages est requis']":readonly="isEdit"></v-text-field></v-col>
  </v-row>
    <!-- Form Fields for Adding/Editing Book -->
    
    <v-row>
      <v-col>
   
    <VueEditor
    v-model="book.description"
      auto-grow
      outlined
      :readonly="isEdit"
      label="Description"
      placeholder="Description"
    >
    </VueEditor>
  </v-col>
  </v-row>
    <v-row>
    <v-col>
      <v-text-field 
      v-model="book.price" 
      @input="book.price = ModificationPrice(book.price)"
      label="Prix" outlined 
      required  
      :rules="[v => !!v || 'Prix est requis']" >
      </v-text-field>
      </v-col>
    <v-col><v-text-field v-model="book.quantity" label="Quantité" outlined required  :rules="[v => !!v || 'Quantité est requis']"></v-text-field></v-col>
    <v-col>
    <v-autocomplete
      v-model="book.levelId"
      :items="levels"
      item-value="id"
      item-text="name"
      label="Niveau"
      outlined
      required
       :rules="[v => !!v || 'Niveau est requis']"
       :readonly="isEdit"
    ></v-autocomplete>
  </v-col>
  </v-row>
  <v-row>
    
  <v-col>
    <v-autocomplete
      v-model="book.categoryId"
      :items="categories"
      item-value="id"
      item-text="name"
      label="Catégorie"
      outlined
      required
       :rules="[v => !!v || 'Catégorie est requis']"
       :readonly="isEdit"
    ></v-autocomplete>
  </v-col>
  <v-col>
    <v-autocomplete
      v-model="book.authorId"
      :items="authors"
      item-value="id"
      item-text="name"
      label="Auteur"
      outlined
      required
       :rules="[v => !!v || 'Auteur est requis']"
       :readonly="isEdit"
    ></v-autocomplete>
    </v-col>

    <!-- Input for Searching Publish Houses -->
    <v-col>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-text-field
        v-model="key"
        label="Maison d'édition"
        outlined
        @input="searchHouses"
        :rules="[v => !!v || 'Maison d\'édition est requis']"
        v-bind="attrs"
        v-on="on"
      ></v-text-field> -->
      <v-autocomplete
  v-model="book.publishHouseId"
  :items="publishHouses"
  item-value="id"  
  item-text="name"
  label="Maison d'édition"
  outlined
  required
  :rules="[v => !!v || 'Maison d\'édition est requis']"
></v-autocomplete>
    </template>

    <!-- Dropdown for Search Results -->
    <v-list v-if="searchResult.length">
      <v-list-item-group>
        <v-list-item
          v-for="publishHouse in searchResult"
          :key="publishHouse.id"
          @click="selectPublishHouse(publishHouse)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ publishHouse.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</v-col>

  </v-row>
    <v-row v-if="keyword">
      <v-col cols="12" sm="6" md="6">
    <!-- VueUploadMultipleImage component for handling images -->
    <vue-upload-multiple-image
      v-model="images"
      :data-images="images"
      name="images"
      drag-text="Please add an image!"
      browse-text="Browse image"
      primary-text="Primary image"
      mark-is-primary-text="Set default image"
      :min-image="1"
      :max-image="2"
      :showEdit="false"
      :multiple="true" 
      :loading="load"
      popup-text="Description default image"
      drop-text="Drag and drop"
      @upload-success="uploadImageSuccess" 
      @before-remove="beforeRemove"
    ></vue-upload-multiple-image>
      </v-col>
    </v-row>
    <v-row v-if="uploadInProgress">
<v-col cols="12">
  <v-progress-linear
    indeterminate
    color="primary"
    height="5"
  ></v-progress-linear>
</v-col>
</v-row>

    <!-- Submit Button -->
    <v-btn type="submit" color="info" :loading="loading">
      {{ isEdit ? 'Modifier' : 'Ajouter' }} Livre
    </v-btn>
  </v-form>
  <v-divider class="my-4"></v-divider>
  <!-- Book Table -->
  <v-card >
      <v-card-title>Liste des Livres</v-card-title>
    <v-text-field
      style="padding:15px"
      v-model="search"
      append-icon="mdi-magnify"
      label="Recherche"
      single-line
      hide-details
      clearable
      :search="search"
    ></v-text-field>
    <v-data-table
  :headers="bookHeaders"
  :items="books"
  item-key="bookId"
  :search="search"
  :loading="loading"
  :no-data-text="noDataText"
  :items-per-page="itemsPerPage"

  hide-default-footer

>

    <!-- Display Image in Table -->
    <template v-slot:item.listFileUrl="{ item }">
      <v-img v-if="item.listFileUrl && item.listFileUrl.length > 0" :src="item.listFileUrl[0]" width="100px" height="100px" style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
    </template>

    <!-- Actions Column in Table -->
    
    <template v-slot:item.edit="{ item }">
      <v-icon  @click="editBook(item)" class="edit-icon">mdi-pencil</v-icon>
    </template>
    <template v-slot:item.delete="{ item }">
      <v-icon  @click="deleteBook(item.id, item.publishHouseId)" class="delete-icon">mdi-delete</v-icon>
    </template>
  </v-data-table>
  <!-- Pagination component -->
  <div class="pagination-container">
    <v-pagination
    v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
  </v-card>

  <!-- Snackbar for Notifications -->
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" top>
    {{ snackbar.message }}
    <v-btn color="white" text @click="snackbar.show = false">Fermer</v-btn>
  </v-snackbar>
  <!-- Confirmation Dialog -->
<v-row justify="center">
    <v-dialog v-model="dialog.show" max-width="530">
      <v-card>
        <v-card-title class="text-h6">
          Etes vous sur de vouloir supprimer le livre ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1 error"
            text
            @click="dialog.show = false"
          >
            Annuler
          </v-btn>

          <v-btn color="info darken-1 " text @click="confirmDelete">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</v-container>
</section>
</template>

<script>
import axios from 'axios';
import Constant from "@/utils/constants";
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
components: {
  VueUploadMultipleImage,
  quillEditor,VueEditor
},
props: ['id'],
data() {
  return {
    currentPage: 1,
    itemsPerPage: 10, // Items per page
    totalPages: 0,
    uploadInProgress: false, // Track upload progress
    isImgUploaded : false,
    ownerId:localStorage.getItem('ownerId'),

    dialog: {
      show: false,
      bookId: null,
      publishHouseId:null,
    },
    library: {},
    books: [],
    searchResults: [],
    searchResult: [],
    menuVisible: false,
    isEdit: false,
    book: {
      libraryId: this.id,
      bookId: null,
      titre: '',
      description: '',
     // nbrPage: 0,
      price: '',
      quantity: '',
      levelId: null,
      categoryId: null,
      authorId: null,
      publishHouseId:null,
      listFileUrl: [],
      listFileId: [],
      createdBy: localStorage.getItem('ownerId')
    },
    keyword: '',
    key:'',
    search: '',
    noDataText: 'No books found',
    images: [],
    load: false,
    valid: false,
    loading: false,
    bookHeaders: [
      { text: 'Image', value: 'listFileUrl' },
      { text: 'Titre', value: 'titre' },
      { text: 'Maison édition', value: 'publishHouseName' },
      { text: 'Prix', value: 'price' },
      { text: 'Quantité', value: 'quantity' },
      { text: 'Modifier', value: 'edit', sortable: false },
      { text: 'Supprimer', value: 'delete', sortable: false },
    ],
    levels: [],
    categories: [],
    authors: [],
    publishHouses:[],
    publishHouseInput: '',
    publishHouseOptions: [], // List of publish houses
    snackbar: { show: false, message: '', color: '' },
  };
},
async created() {
  await this.fetchLibraryDetails();
  await this.fetchBooks();
  await this.fetchLevels();
  await this.fetchCategories();
  await this.fetchAuthors();
  await this.fetchPublishHouse();
},
mounted() {
  document.addEventListener('click', this.handleClickOutside);
},
beforeDestroy() {
  document.removeEventListener('click', this.handleClickOutside);
},
methods: {
  async fetchLibraryDetails() {
    try {
      const response = await axios.get(Constant.QORANI+`library/library/get/${this.id}`);
      this.library = response.data;
    } catch (error) {
      this.handleError('Échec de la récupération des détails de la bibliothèque', error);
    }
  },
  async fetchBooks() {
 // this.loading = true;
 console.log("Fetching books...");
 console.log({
          pageNumber: this.currentPage - 1,
          pageSize: this.itemsPerPage,
          fieldId: this.library.id,
      });

  try {
    
    const url = `${Constant.QORANI}library/book/getallByLibraryId`;
    console.log(" URL:", url);
      const response = await axios.post(Constant.QORANI+`library/book/getallByLibraryId`, {
          pageNumber: this.currentPage - 1,
          pageSize: this.itemsPerPage,
          fieldId: this.library.id,
      });
      console.log("Response data:", response.data);
      // this.books = response.data.bookResponseList;
      this.books = response.data.bookResponseList || []; 

      this.totalPages = response.data.totalPageNumber;
  } catch (error) {
      this.handleError('Failed to fetch books', );
  } 
},

handlePageChange(page) {
  this.currentPage = page;
  this.fetchBooks(); // Fetch the new page of books
},
  async fetchLevels() {
    try {
      const response = await axios.get(Constant.QORANI+`library/level/getall`);
      this.levels = response.data;
    } catch (error) {
      this.handleError('Échec de la récupération des niveaux', error);
    }
  },
  async fetchCategories() {
    try {
      const response = await axios.get(Constant.QORANI+`library/category/getall`);
      this.categories = response.data;
    } catch (error) {
      this.handleError('Échec de la récupération des catégories', error);
    }
  },
  async fetchAuthors() {
    try {
      const response = await axios.get(Constant.QORANI+`library/author/getall`);
      this.authors = response.data;
    } catch (error) {
      this.handleError('Échec de la récupération des auteurs', error);
    }
  },
  async fetchPublishHouse() {
    try {
      const response = await axios.get(Constant.QORANI+`library/publishHouse/getall`);
      this.publishHouses = response.data;
    } catch (error) {
      this.handleError('Échec de la récupération des maisons de publication', error);
    }
  },
addNewPublishHouse() {
    if (this.key && !this.publishHouses.some(house => house.name === this.key)) {
      axios.post(Constant.QORANI +`library/publishHouse/add`, { name: this.key })
        .then(response => {
          this.publishHouses.push(response.data);
          this.book.publishHouseId = response.data.id;
          this.key = ''; // Clear the input field
          this.searchResult = []; // Clear search results
        })
        .catch(error => {
          console.error('Error adding publish house:', error);
        });
    }
  },
  ModificationPrice(value) {
  if (typeof value !== 'string') {
    value = String(value);
  }
  const normalizedValue = value.replace(',', '.');
  return parseFloat(normalizedValue);
},
  async submitForm() {
this.$refs.form.validate();

if (!this.valid ) {
  console.log("Form validation failed.");
  return;
}
try {
  this.loading = true;
  // Ensure libraryId is set
  this.book.libraryId = this.library.id;
  // Convert fields to correct data types
  this.book.nbrPage = parseInt(this.book.nbrPage, 10);
  this.book.price = this.ModificationPrice(this.book.price,10);
  this.book.quantity = parseInt(this.book.quantity, 10);
  //this.book.publishHouseId = this.publishHouses.find(house => house.name === this.key)?.id || null;
  let response;
  if (this.book.id) {
    if(this.isEdit){
      // Updating existing book
      response = await axios.post(Constant.QORANI + `library/library/editBookLibrary`, {
      libraryId: this.library.id,
      bookId: this.book.id,
      publishHouseId: this.book.publishHouseId,
      price: this.book.price,
      quantity: this.book.quantity,
      

    });
    if(response.data.success){
      this.showSnackbar('Livre mis à jour avec succès', 'success');
    }else{
      this.showSnackbar('Mise à jour de l échec', 'Echec');
    }
    }
    else{
      // ajout existing book
      response = await axios.post(Constant.QORANI + `library/library/addBookToLibrary`, {
      libraryId: this.library.id,
      bookId: this.book.id,
      publishHouseId: this.book.publishHouseId,
      price: this.book.price,
      quantity: this.book.quantity,
    });
    if(response.data.success){
      this.showSnackbar('Livre ajouté avec succès', 'success');
    }else{
      this.showSnackbar('Echec ajout', 'Echec');
    }
    }
   
  } else { 
    // Adding new book
    this.book.titre = this.keyword;
    if(this.isImgUploaded){
    response = await axios.post(Constant.QORANI + `library/book/add`, this.book);
    //if(!this.isImgUploaded){
    this.book.id = response.data.id;

    //this.showSnackbar('Livre ajouté avec succès', 'success');
    //}
    // Associate the newly added book with the library
    response=await axios.post(Constant.QORANI + `library/library/addBookToLibrary`, {
      libraryId: this.library.id,
      bookId: this.book.id,
      publishHouseId: this.book.publishHouseId,
      price: this.book.price,
      quantity: this.book.quantity,
    });
    this.showSnackbar('Livre ajouté avec succès', 'success');
  }
  this.resetForm();
  }
  this.resetForm();
  this.$refs.form.reset(); // Reset the form after submission
  await this.fetchBooks(); // Refresh the book list
} catch (error) {
  this.handleError('Submission failed', error);
} finally {
  this.loading = false; // Stop loading indicator
}
},
  editBook(book) {
    event.preventDefault();
this.book = { ...book }; 
this.isEdit = true;
// this.images.push({
//         name: "",
//         path: book.listFileUrl,
//         highlight: 1,
//         default: 1,
//       });
if (book.listFileUrl !== null && Array.isArray(book.listFileUrl)) {
  this.images = book.listFileUrl.map(url => ({
    name: "", 
    path: url,
    highlight: 1,
    default: 1,
  }));
} else {
  this.images = []; 
}
this.keyword = book.titre || ''; 
this.key = this.publishHouses.find(ph => ph.id === book.publishHouseId)?.name || '';

const ownerId = localStorage.getItem('ownerId'); 
const ownerIdValue = parseInt(ownerId, 10);
this.readonlytest = this.isEdit && this.book.createdBy !== ownerIdValue;

this.$nextTick(() => {
  const formElement = this.$refs.form.$el; 
  formElement.scrollIntoView({ behavior: 'smooth' }); 
});

  },
  deleteBook(id, publishHouseId) {
this.dialog.bookId = id;
this.dialog.publishHouseId = publishHouseId; // Ensure publishHouseId is passed
this.dialog.show = true;
},
  async confirmDelete() {
    try {
      await axios.post(Constant.QORANI + `library/library/removeBookFromLibrary`, {
        libraryId: this.library.id,
        bookId: this.dialog.bookId,
        publishHouseId: this.dialog.publishHouseId, // Ensure this is passed
        
      });
      this.showSnackbar('Livre supprimé avec succès', 'success');
      await this.fetchBooks();
    } catch (error) {
      this.handleError('Erreur lors de la suppression du livre', error);
    } finally {
      this.dialog.show = false; // Close the dialog
    }
  },
  async searchBooks() {
    if (this.keyword.length < 3) {
      this.searchResults = [];
      return;
    }
    try {
      this.loading = true;
      const response = await axios.get(Constant.QORANI+`library/book/findByKeyword/${this.keyword}`);
      this.searchResults = response.data;
    } catch (error) {
      this.handleError('Failed to search books', error);
    } finally {
      this.loading = false;
    }
  },
  selectBook(book) {
  // Check if the publish house is valid
  if (book) {
    // Update book's publishHouseId or any relevant property
    this.book = { ...book, price: '', quantity: '' ,publishHouseId:''}; // Copy book details and clear price & quantity
    this.keyword = book.titre || ''; // Set the keyword input to the selected book's title
    // Optionally clear or update other related fields
    this.searchResults = [];
  } else {
    // Handle the case where no publish house is selected
    console.log('No Book selected.');
  }
  this.menuVisible = false; // Close the dropdown when a book is selected
},
handleClickOutside(event) {
    if (this.$el && !this.$el.contains(event.target)) {
      this.menuVisible = false; // Close the dropdown when clicking outside
    }
  },
  /************************************************************************************************/
  async searchHouses() {
    if (this.key.length < 3) {
      this.searchResult = [];
      return;
    }
    try {
      this.loading = true;
      const response = await axios.get(Constant.QORANI+`library/publishHouse/getByKeyword/${this.key}`);
      this.searchResult = response.data;
    } catch (error) {
      this.handleError('Failed to search books', error);
    } finally {
      this.loading = false;
    }
  },
  selectPublishHouse(publishHouse) {
  // Check if the publish house is valid
  if (publishHouse) {
    // Update book's publishHouseId or any relevant property
    this.book.publishHouseId = publishHouse.id;
    this.key = publishHouse.name || '';

    // Optionally clear or update other related fields
    this.searchResult = [];
    // For example, if bookId needs to be managed:
     this.bookId = null; // Clear bookId if necessary
  } else {
    // Handle the case where no publish house is selected
    console.log('No publish house selected.');
  }
  this.menuVisible = false; // Close the dropdown when a book is selected
},
  /************************************************************************************************/
  resetForm() {
    this.book = {
      libraryId: this.id,
      bookId: null,
      titre: '',
      description: '',
      nbrPage: 0,
      price: '',
      quantity: '',
      levelId: null,
      categoryId: null,
      authorId: null,
      publishHouseId:null,
      listFileUrl: [],
      listFileId: [],
      createdBy: localStorage.getItem('ownerId')
    };
    this.keyword = ''; // Clear the keyword input
    this.searchResults = []; // Clear the search results
    this.isEdit = false;
  },
  handleError(message, error) {
    console.error(message, error);
    this.showSnackbar(`${message}: ${error.message}`, 'error');
  },
  showSnackbar(message, color) {
    this.snackbar.message = message;
    this.snackbar.color = color;
    this.snackbar.show = true;
  },
  handleImagesUpdated(images) {
    
    const uploadedImage = images[0];
    console.log("Uploaded image:", uploadedImage);
  },
  async uploadImageSuccess(formData, index, fileList) {
    try {
      this.uploadInProgress = true; // Show the upload progress indicator
      this.isImgUploaded = false;
      const uploadedImage = fileList[index];
      const imageName = uploadedImage.name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(currentDateTime.getMonth() + 1)
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      
      const file = this.convertImagePathToFile(uploadedImage);
      const storageRef = firebase.app().storage().ref(`books/${newImageName}`);
      // Perform the upload task
      const uploadTask = storageRef.put(file, { contentType });
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle upload progress if needed
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadPercentage = progress;
        },
        (error) => {
          console.error("Error uploading image:", error);
          alert(`Error uploading image ${error.message}`);
          
        },
        async () => {
          // Upload completed successfully
          const url = await storageRef.getDownloadURL();
          // Update book object with uploaded image URL and ID
          try {
            const response = await fetch(Constant.QORANI + "files/upload", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ fileName: imageName, fileUrl: url }),
            });

            if (response.ok) {
              const responseData = await response.json();
              this.book.listFileId.push(responseData.id); // Assuming it's an array of IDs
              this.book.listFileUrl.push(url); // Assuming it's an array of URLs
              this.isImgUploaded = true;
              this.uploadInProgress = false;
            } else {
              console.error(
                "Error uploading image. Server response status:",
                response.status
              );
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading image:", error);
      alert(`Error uploading image ${error.message}`);
    }
  },
  beforeRemove(index, done, fileList) {
console.log('Avant suppression :', index, fileList);

const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");

if (confirmation) {
  console.log('Suppression confirmée pour l\'index :', index);
  this.images = [];
  this.book.listFileId = [];
  this.book.listFileUrl = [];
  this.$emit('input', this.images);
  
  done(); // Appel de done() pour continuer
} 
},
  convertImagePathToFile(element) {
    const byteString = atob(element.path.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: "image/jpeg" });
    return new File([blob], element.name);
  },
},
};
</script>

<style>
/* Add custom styles as needed */
/* Icon styling */
.v-icon {
cursor: pointer;
transition: color 0.3s, transform 0.3s;
}

.edit-icon {
color: #1976d2; /* Edit icon color */
}

.edit-icon:hover {
color: #1565c0; /* Darker blue on hover */
transform: scale(1.2); /* Slightly larger on hover */
}

.delete-icon {
color: #d32f2f; /* Delete icon color */
}

.delete-icon:hover {
color: #b71c1c; /* Darker red on hover */
transform: scale(1.2); /* Slightly larger on hover */
}

.libraryBook-form-section {
padding: 24px;
background-color: #f4f7f9;
border-radius: 12px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.libraryBook-form {
padding: 20px;
background-color: white;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.q-editor {

margin-bottom: 24px; /* Space below editor */
border-radius: 8px; /* Rounded corners */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
background-color: #f9f9f9; /* Light background color */
}
.v-divider {
margin: 20px 0;
}
</style>

<template>
    <v-container>
      <v-card>
        <v-card-title class="headline">Livres de l'Auteur</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="bookHeaders"
            :items="authorBooks"
            class="elevation-1 books-table"
            :search="search"
            :no-data-text="noBooksText"
          >
          <template v-slot:top>
        <!-- Search Input for Table -->
        <v-toolbar flat>
          <v-text-field v-model="search" label="Recherche"></v-text-field>
        </v-toolbar>
      </template>
            <template v-slot:item.listFileUrl="{ item }">
              <v-img v-if="item.listFileUrl && item.listFileUrl.length > 0" :src="item.listFileUrl[0]" width="100px" height="100px" style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="$router.back()">Retour</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import Constant from "@/utils/constants";
  
  export default {
    props: ['id'],
    data() {
      return {
        authorBooks: [],
        search: '',
        noBooksText: 'Aucun livre trouvé',
        bookHeaders: [
          { text: 'Image', value: 'listFileUrl' },
          { text: 'Titre', value: 'titre' },
          { text: 'Description', value: 'description' },
          { text: 'Nombre de Pages', value: 'nbrPage' },
        ],
        loading: {
          fetch: false,
        },
      };
    },
    methods: {
      async fetchAuthorBooks() {
        this.loading.fetch = true;
        try {
          const response = await axios.post(Constant.QORANI + 'library/book/getallByAuthorId', {
            pageNumber: 0,
            pageSize: 100,
            fieldId: this.id,
          });
          this.authorBooks = response.data;
          console.log('Books fetched successfully');
        } catch (error) {
          console.error('Error fetching books', error);
        } finally {
          this.loading.fetch = false;
        }
      },
    },
    mounted() {
      this.fetchAuthorBooks();
    },
  };
  </script>
  
  <style scoped>
  /* Add any styles specific to this component here */
  </style>
  
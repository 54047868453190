export default {
  apiKey: "AIzaSyCC3xZxOgzO9qYHS7uy9L_QO1QvpdjncGk",
  authDomain: "qorani-c5ac6.firebaseapp.com",
  databaseURL:
    "https://qorani-c5ac6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "qorani-c5ac6",
  storageBucket: "qorani-c5ac6.appspot.com",
  messagingSenderId: "442961569677",
  appId: "1:442961569677:web:e8caaeebefe9b4dfeb7fc4",
  measurementId: "G-FLMT3MJFX3",
};